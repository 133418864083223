import { useEffect, useCallback, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { userAPI } from "../api";
import { add } from "date-fns";
import { useSelector } from "react-redux";
import { ACTIVITY_EVENT, IDLE_PERIOD } from "../consts";

function useLogUserSession(
  activityEvent,
  additionalPayload = {},
  isActive = true
) {
  const userId = useSelector((state) => state.firebase.auth.uid);
  const [isVisible, setIsVisible] = useState(true);

  const logActivity = useCallback(
    (type) => {
      const now = new Date();
      userAPI.log({
        action_name: activityEvent,
        user_id: userId,
        created_at: now,
        ttl: add(now, { months: 1 }),
        payload: {
          type,
          ...(type === ACTIVITY_EVENT.END ? { idle_period: IDLE_PERIOD } : {}),
          ...additionalPayload
        }
      });
    },
    [activityEvent, userId, additionalPayload]
  );

  const onActive = useCallback(() => {
    if (isActive && isVisible) {
      logActivity(ACTIVITY_EVENT.START);
    }
  }, [isActive, isVisible, logActivity]);

  const onIdle = useCallback(() => {
    if (isVisible) {
      logActivity(ACTIVITY_EVENT.END);
    }
  }, [isVisible, logActivity]);

  const { reset } = useIdleTimer({
    onIdle,
    onActive,
    timeout: IDLE_PERIOD,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove"
    ],
    element: document,
    startOnMount: true
  });

  useEffect(() => {
    if (activityEvent) {
      if (isActive && isVisible) {
        onActive();
      } else if (!isVisible) {
        onIdle();
      }
    }
  }, [activityEvent, isActive, isVisible, onActive, onIdle]);

  useEffect(() => {
    if (activityEvent) {
      const handleVisibilityChange = () => {
        setIsVisible(!document.hidden);
        if (!document.hidden) {
          reset();
        }
      };

      const handleBeforeUnload = () => {
        if (isVisible) {
          onIdle();
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        window.removeEventListener("beforeunload", handleBeforeUnload);
        if (isVisible) {
          onIdle();
        }
      };
    }
  }, [activityEvent, onIdle, reset, isVisible]);

  return { reset };
}

export default useLogUserSession;
