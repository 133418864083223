// Dependencies
import React, { useState } from "react";
import { isEmulated, crossed } from "../../firebase";
import { useImpersonate } from "../../hooks";
// Redux dependencies
import { useSelector } from "react-redux";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Typography
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CodeIcon from "@mui/icons-material/Code";
import TerminalIcon from "@mui/icons-material/Terminal";
import { useHistory } from "react-router-dom";

function AdminNotification() {
  //Hooks
  const history = useHistory();
  const { stopImpersonate } = useImpersonate();

  const [expand, setExpand] = useState(true);
  const [showOwnershipDialog, setShowOwnershipDialog] = useState(false);
  const [takingOwnershipConfirmed, setTakingOwnershipConfirmed] =
    useState(false);

  const takeOwnership = () => {
    localStorage.removeItem("impersonate.originalUser");
    window.location.reload();
  };

  const stopEmulating = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("emulated", false);
    const pathWithParams = url.pathname + url.search + url.hash;
    history.push(pathWithParams);
    window.location.reload();
  };

  //Redux State
  const originalAuth = useSelector((state) => state.user.original_auth);

  //Derived State
  const isImpersonation = originalAuth && originalAuth !== -1;

  // Behavior
  const getContent = () => {
    if (isImpersonation) {
      return expand ? (
        "Impersonation"
      ) : (
        <SupervisedUserCircleIcon color="inherit" />
      );
    } else if (isEmulated) {
      return expand ? "Emulated" : <CodeIcon color="inherit" />;
    } else if (crossed) {
      return expand ? (
        "crossed with {crossed} backend"
      ) : (
        <TerminalIcon color="inherit" />
      );
    }
  };

  const iconProp = expand ? {} : { icon: false };

  //Render
  if (isEmulated || crossed || isImpersonation) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Box
          sx={{
            margin: "8px",
            position: "absolute",
            zIndex: "10000",
            width: expand ? "600px" : "100px",
            top: expand ? "0px" : "-16px"
          }}>
          <Alert
            severity="info"
            {...iconProp}
            sx={{
              "& .MuiAlert-action": { paddingTop: "0" },
              "& .MuiAlert-message": { paddingBottom: expand ? "8px" : "0" },
              display: "flex",
              alignItems: expand ? "center" : "flex-end"
            }}
            action={
              <Box
                sx={{
                  display: "flex",
                  alignItems: expand ? "center" : "flex-end"
                }}>
                {isImpersonation && expand && (
                  <>
                    <Link
                      variant="body2"
                      color="inherit"
                      underline="none"
                      component="button"
                      onClick={stopImpersonate}
                      sx={{ marginRight: "16px" }}>
                      Stop impersonation
                    </Link>
                    <Link
                      variant="body2"
                      color="inherit"
                      underline="none"
                      component="button"
                      onClick={() => setShowOwnershipDialog(true)}
                      sx={{ marginRight: "8px" }}>
                      Take ownership
                    </Link>
                  </>
                )}
                {isEmulated && expand && (
                  <>
                    <Link
                      variant="body2"
                      color="inherit"
                      underline="none"
                      component="button"
                      onClick={stopEmulating}
                      sx={{ marginRight: "16px" }}>
                      Stop emulating
                    </Link>
                  </>
                )}
                <IconButton
                  onClick={() => setExpand(!expand)}
                  size="small"
                  color="inherit">
                  {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>
            }>
            {getContent()}
          </Alert>
        </Box>
        {showOwnershipDialog && (
          <Dialog
            open={showOwnershipDialog}
            onClose={() => {
              setShowOwnershipDialog(false);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            fullWidth>
            <DialogTitle id="form-dialog-title">
              Delete confirmation
            </DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to do this?</Typography>
              <FormGroup>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={takingOwnershipConfirmed}
                      onChange={(event) =>
                        setTakingOwnershipConfirmed(event.target.checked)
                      }
                    />
                  }
                  label="I'm sure"
                />
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowOwnershipDialog(false);
                  setTakingOwnershipConfirmed(false);
                }}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setShowOwnershipDialog(false);
                  setTakingOwnershipConfirmed(false);
                  takeOwnership();
                }}
                color="primary"
                disabled={!takingOwnershipConfirmed}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    );
  } else return null;
}

export default AdminNotification;
