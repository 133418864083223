// Dependencies
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import flatten from "flat";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { create } from "jss";
import rtl from "jss-rtl";
import { useErrorBoundary } from "use-error-boundary";
import SnackBars from "./SnackBars";
import ErrorPage from "./ErrorPage";

import { userAPI } from "./api";
import { captureException } from "./utils/errorHandlers";
import {
  firebaseApp,
  firestore,
  httpCallables,
  firebaseConfig
} from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";

import { add } from "date-fns";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setCourses } from "./redux/coursesSlice";
import { setTexts, setSelectedTextId } from "./redux/textsSlice";
import { userNavigated } from "./redux/firebaseMiddleware";
import {
  setAuth,
  setAdmin,
  defaultProfile,
  setProfile,
  selectIsImpersonation,
  setTasksViewed,
  setDataViewer,
  setOriginalUser
} from "./redux/userSlice";
import {
  setReaderActionData,
  defaultPersisentActionState
} from "./redux/readerActionsSlice";

// Components
import "./App.css";
import { UserApp } from "./UserApp";

import AdminNotificaitons from "./components/admin/AdminNotificaiton";
import useGetTheme from "./hooks/useGetTheme";
import SignIn from "./components/auth/SignIn";
import PangeaSpinner from "./components/SharedComponents/PangeaSpinner";
import TermsOfUseDialog from "./components/auth/TermsOfUseDialog";
import { OfflineDialog } from "./components/SharedComponents/OfflineDialog";

// Intl
import Hebrew from "./translations/he.js";
import English from "./translations/en";

//Locatlization for date pickers
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Material UI
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

//rendition context
import { RenditionProvider } from "./RenditionContext";
import { useQuery } from "./hooks";
import { selectLocale, selectTextDirection } from "./redux/firestoreSelectors";
import { FirebaseAppProvider } from "reactfire";

function App() {
  const dispatch = useDispatch();
  // const locale = useSelector(state => state.user.userProfile.language);
  const locale = useSelector((state) => selectLocale(state));
  const location = useLocation();
  const history = useHistory();
  const theme = useGetTheme();
  const { text_id } = useQuery();
  const [tokenRedirect, setTokenRedirect] = useState(false);
  const [gotCourses, setGotCourses] = useState(false);
  const [user, authLoading, error] = useAuthState(firebaseApp.auth());

  const loggedOut = useSelector((state) => state.user.loggedOut);

  const textDirection = useSelector((state) => selectTextDirection(state));
  const auth = useSelector((state) => state.user.auth);
  const readerActionsData = useSelector(
    (state) => state.readerActions.persistentActionState
  );
  const isImpersonation = useSelector(selectIsImpersonation);
  const userProfile = useSelector((state) => state.user.userProfile);
  const { ErrorBoundary, didCatch } = useErrorBoundary({
    onDidCatch: (error) => captureException(error)
  });

  const isLoggedin = Boolean(firebaseApp.auth().currentUser); // There is a problem with the current logout flow which created a bug where there is a userId for a moment after loggin out. using firebase directly to overcome this until there's a better fix.
  const userUid = user ? user.uid : null;
  const userDisplayName = user ? user.displayName : null;
  const userPhotoUrl = user ? user.photoURL : null;
  const userEmail = user ? user.email : null;

  let lang;
  switch (locale) {
    case "en":
      lang = English;
      break;
    case "he":
      lang = Hebrew;
      break;
    default:
      lang = English;
  }

  // Configure JSS
  const jss = create({
    plugins: [...jssPreset().plugins, textDirection === "rtl" && rtl()]
  });

  const shouldShowApp = () => {
    // This whole flow need to be refactored
    //will show app when (a) user is logged in (b) got all user related data
    return (
      isLoggedin &&
      gotCourses && //waiting for courses to make spinner go away
      auth &&
      user &&
      readerActionsData &&
      readerActionsData.highlightColor
    );
  };

  useEffect(() => {
    // force refresh on logout
    if (loggedOut) {
      window.location.reload();
    }
  }, [loggedOut]);

  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    if (token) {
      setTokenRedirect(true);
      firebaseApp.auth().signInWithCustomToken(token);
    } else {
      if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        //we saved the email in local storage when generated,
        //but if the user used another browser it is not available , so promprt them.
        let savedEmail = window.localStorage.getItem("emailForSignIn");
        if (!savedEmail) {
          savedEmail = window.prompt(
            "Please provide your email for confirmation"
          );
        }
        // The client SDK logs in the user based on link
        firebaseApp
          .auth()
          .signInWithEmailLink(savedEmail, window.location.href)
          .then(() => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch((err) => {
            //TODO: add a more descriptive err
            captureException(err);
            setAuth(0);
          });
      }
    }
  }, []);

  useEffect(() => {
    const syncProfileAndCourses = async (courseId, path) => {
      let studentCourses = [];
      let studentProfile = {};

      const courseTextPromise = httpCallables
        .readUserCoursesAndTexts()
        .then(({ data }) => {
          const { courses, texts } = JSON.parse(data);
          dispatch(setCourses(courses));
          dispatch(setTexts(texts));
          studentCourses = courses;
        })
        .finally(() => {
          setGotCourses(true);
        });

      const userPromise = firestore
        .doc("users/" + userUid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            if (!tokenRedirect && location.pathname === "/") {
              history.push(data.location);
            }
            const newUserProfile = { ...userProfile, ...data };
            studentProfile = newUserProfile;

            dispatch(setProfile(newUserProfile));

            if (data?.readerActionData?.highlightColor) {
              // override the default setting with the saved ones in case simething is added to the default
              const readerActionData = {
                ...defaultPersisentActionState,
                ...data.readerActionData
              };
              dispatch(setReaderActionData(readerActionData));
            } else {
              dispatch(setReaderActionData(defaultPersisentActionState));
            }
            if (!text_id) dispatch(setSelectedTextId(data.selectedTextId));
            dispatch(setTasksViewed(data.tasksViewed));
          } else {
            dispatch(setReaderActionData(defaultPersisentActionState));

            dispatch(setProfile(defaultProfile));
            if (!tokenRedirect && location.pathname === "/") {
              history.push("/library");
            }
          }
        })
        .catch((err) =>
          captureException(
            err,
            `Error fetcfhing user profile from firebase, user: ${userUid}`
          )
        );
      //redirect from token
      await Promise.all([courseTextPromise, userPromise]).then(() => {
        const filteredCourse = studentCourses?.find(
          (c) => c.id === Number(courseId)
        );

        if (filteredCourse) {
          const newUserProfile = {
            ...studentProfile,
            selectedCourse: filteredCourse
          };
          dispatch(setProfile(studentProfile));
        } else {
          const updateUserProfileAndRedirect = (profile, course) => {
            const newUserProfile = {
              ...profile,
              selectedCourse: course || {}
            };
            dispatch(setProfile(newUserProfile));
          };

          if (
            studentCourses?.length >= 1 &&
            studentProfile &&
            !studentProfile.selectedCourse
          ) {
            updateUserProfileAndRedirect(studentProfile, studentCourses[0]);
          } else if (!studentCourses?.length) {
            updateUserProfileAndRedirect(studentProfile);
          }
        }
      });
      // }
      tokenRedirect && history.push(path);
    };

    if (isLoggedin) {
      let sp = new URLSearchParams(location.search);
      let path = sp.get("redirect");
      let courseId = sp.get("course_id")
        ? sp.get("course_id")
        : new URLSearchParams(path?.split("?")[1]).get("course_id");
      let coursePart = courseId ? `?course_id=${courseId}` : "";
      if (!path) path = `/tasks${coursePart}`;

      syncProfileAndCourses(courseId, path);
    }
  }, [isLoggedin, isImpersonation, userUid]);

  useEffect(() => {
    if (userUid) {
      Sentry.setUser({ id: userUid });

      const now = new Date();
      const diff =
        now.getTime() - new Date(user.metadata.lastSignInTime).getTime();
      if (diff < 20000) {
        // if user just signed in in the past 20 seconds
        userAPI.log({
          action_name: "LOGIN",
          user_id: user.uid,
          created_at: now,
          ttl: add(now, { months: 1 }),
          payload: {}
        });
      }
    } else {
      Sentry.setUser(null);
    }
  }, [userUid]);

  useEffect(() => {
    const relativeUrl = location.pathname + location.search;
    if (
      !isImpersonation &&
      userUid &&
      !location.pathname.includes("logout") &&
      location.pathname !== "/"
    ) {
      firestore
        .doc("users/" + userUid)
        .update({ location: relativeUrl })
        .catch((err) =>
          captureException(
            err,
            `Error updating firestore with current location, user: ${userUid} location: ${relativeUrl}`
          )
        );
    }

    if (userUid) dispatch(userNavigated({ location: relativeUrl }));
  }, [dispatch, location, userUid, isImpersonation]);

  useEffect(() => {
    if (
      !isImpersonation &&
      readerActionsData &&
      readerActionsData.highlightColor &&
      userUid
    ) {
      try {
        firestore
          .doc("users/" + userUid)
          .update({ readerActionData: readerActionsData });
      } catch (err) {
        captureException(
          err,
          `Error updating readerAction in firestore, user: ${userUid} data: ${readerActionsData}`
        );
      }
    }
  }, [readerActionsData, userUid, isImpersonation]);

  useEffect(() => {
    if (!isLoggedin) return;

    firebaseApp
      .auth()
      .currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        // Confirm the user is an Admin or Data viewer.
        const role = idTokenResult.claims?.role;
        dispatch(setAdmin(role === "admin"));
        dispatch(setDataViewer(role === "dataViewer"));
      });

    dispatch(
      setAuth({
        displayName: !isImpersonation ? userDisplayName : userUid,
        photoURL: !isImpersonation ? userPhotoUrl : "",
        uid: userUid,
        email: !isImpersonation ? userEmail : "",
        isAdmin: !isImpersonation ? auth.isAdmin : false,
        isDataViewer: !isImpersonation ? auth.isDataViewer : false
      })
    );

    const originalUser = localStorage.getItem("impersonate.originalUser");
    if (originalUser) {
      dispatch(setOriginalUser(originalUser));
    }

    // Check if the user is an app admin
  }, [dispatch, isLoggedin, userDisplayName, userEmail, userPhotoUrl, userUid]);

  const renderApp = () => {
    //adding token to if for LTI - sometimes slowness cause the login screen to appear when laoder should
    //still be visible  need to verify this doesn't break anything
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    if (shouldShowApp()) {
      return (
        <>
          <TermsOfUseDialog />
          <RenditionProvider>
            <UserApp />
          </RenditionProvider>
        </>
      );
    } else if (!user && !token && !authLoading) {
      //TODO: add a more descriptive err
      if (error) captureException(error);
      return <SignIn />;
    } else return <PangeaSpinner />;
  };

  const [isOffline, setIsOffline] = useState(
    window.Offline && window.Offline.state !== "up"
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <IntlProvider
            locale={locale}
            messages={flatten(lang, { safe: true })}
            onError={() => {}}>
            <DndProvider backend={HTML5Backend}>
              <StylesProvider jss={jss}>
                <div className="App" dir={textDirection}>
                  {didCatch && <ErrorPage />}
                  {!isOffline && (
                    <ErrorBoundary>
                      <FirebaseAppProvider
                        firebaseConfig={firebaseConfig.options}>
                        {renderApp()}
                      </FirebaseAppProvider>
                    </ErrorBoundary>
                  )}
                </div>

                <>
                  <AdminNotificaitons />
                  <OfflineDialog
                    isOffline={isOffline}
                    setIsOffline={setIsOffline}
                  />
                  <SnackBars />
                </>
              </StylesProvider>
            </DndProvider>
          </IntlProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default withRouter(App);
