import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "../../hooks";
import useScrollbarWidth from "../../hooks/useScrollbarWidth";
import PropTypes from "prop-types";
import ReactReader from "./ReactReader/ReactReader";
import ReactPdfReader from "./pdf/ReactPdfReader";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { isPdfSelector } from "../../redux/textsSlice";
import { setShowAllHighlights } from "../../redux/readerActionsSlice";
import { selectIsSuggestions } from "../../redux/firestoreSelectors";
import { selectSubmission, selectTask } from "../../redux/tasksSlice";
import { Alert, Box, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Fix for v3 branch of epub.js -> needs ePub to by a global var
//const storage = global.localStorage || null;

const useStyles = makeStyles((theme) => ({
  bookView: {
    flexGrow: "1",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%"
  },
  bookViewWithSuggestions: {
    paddingTop: theme.spacing(6)
  }
}));

function BookView({
  readOnly,
  minimalBar,
  disableInteractions = false,
  isVisible = false
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { submission_id } = useQuery();
  const isPdf = useSelector(isPdfSelector);
  const showAllHighlights = useSelector(
    (state) => state.readerActions.persistentActionState.showAllHighlights
  );
  const isSuggestions = useSelector((state) => selectIsSuggestions(state));
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const scrollbarWidth = useScrollbarWidth();

  const [showGrBanner, setShowGrBanner] = useState(false);

  useEffect(() => {
    return () => {
      if (showAllHighlights) {
        dispatch(setShowAllHighlights());
      }
    };
  }, []);

  useEffect(() => {
    if (task.task_type === "guidedReading") setShowGrBanner(true);
  }, [task]);

  return (
    <div
      className={clsx(
        classes.bookView,
        isSuggestions && isPdf && classes.bookViewWithSuggestions
      )}
      id="the-text-reader-view">
      {showGrBanner && (
        <Box
          sx={{
            margin: "16px",
            position: "absolute",
            zIndex: "999",
            width: `calc(100% - ${32 + scrollbarWidth}px)`
          }}>
          <Alert
            severity="info"
            sx={{ "& .MuiAlert-action": { paddingTop: "0" } }}
            action={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link
                  component="a"
                  variant="body2"
                  color="inherit"
                  underline="none"
                  href="https://alethea.zendesk.com/hc/en-us/articles/27986193855121-Guided-Reading-Assignment-Step-1-Add-Questions-and-Highlight"
                  target="_blank"
                  rel="noopener noreferrer">
                  {intl.formatMessage({
                    id: "task.grBanner.learnMore",
                    defaultMessage: "Learn more"
                  })}
                </Link>
                <IconButton
                  onClick={() => setShowGrBanner(false)}
                  size="small"
                  color="inherit">
                  <CloseIcon />
                </IconButton>
              </Box>
            }>
            <Box>
              <Typography
                sx={{ fontWeight: "bold", marginRight: "8px" }}
                component="span">
                {intl.formatMessage({
                  id: "task.grBanner.title",
                  defaultMessage: "Guided Reading assignment"
                })}
              </Typography>
              {intl.formatMessage({
                id: "task.grBanner.studentContent",
                defaultMessage:
                  "This assignment allows you to engage more deeply with the material by adding your own questions."
              })}
            </Box>
          </Alert>
        </Box>
      )}
      {isPdf ? (
        <ReactPdfReader
          disableInteractions={disableInteractions}
          minimalBar={minimalBar}
          readOnly={readOnly}
          isVisible={isVisible}
        />
      ) : (
        <ReactReader
          minimalBar={minimalBar}
          readOnly={readOnly}
          disableInteractions={disableInteractions}
          isVisible={isVisible}
        />
      )}
    </div>
  );
}

BookView.propTypes = {
  readOnly: PropTypes.bool,
  disableInteractions: PropTypes.bool,
  isVisible: PropTypes.bool,
  rendition: PropTypes.object
};

export default BookView;
