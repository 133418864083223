import React, { useEffect, useState } from "react";
import { firebaseFunctions, httpCallables } from "../../firebase";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { selectCourse } from "../../redux/coursesSlice";

import useConvertToTimeZone from "../../hooks/useConvertToTimezone";

import { useHistory } from "react-router-dom";
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Typography,
  Button
} from "@mui/material";
import { useIntl } from "react-intl";
import { QUESTION_INTL } from "../../consts";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100w",
    height: "100vh"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "80%",
    marginRight: "auto",
    marginBottom: "24px"
  },
  selectEmpty: {
    width: "80%"
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function Tasks({ task }) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const course = useSelector((state) => selectCourse(state, task.course_id));
  const currentUser = useSelector((state) => state.user.auth);
  const [formattedDueDate] = useConvertToTimeZone(
    task.due_date,
    course.timezone
  );

  return (
    <TableRow
      key={task.id}
      onClick={(e) => {
        history.push("adminTaskView/" + task.id);
      }}>
      <TableCell className={classes.cell} component="th" scope="row">
        {task.id}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.name}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.task_type
          ? intl.formatMessage({
              id: `task.type.${task.task_type}`,
              defaultMessage: task.task_type
            })
          : ""}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {formattedDueDate}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.submitted_count} / {task.task_users}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.questions_open_ended_count}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.questions_find_in_text_count}
      </TableCell>
      <TableCell className={classes.cell} align="left">
        {task.questions_multi_choice_count}
      </TableCell>
      {currentUser.isAdmin && (
        <TableCell className={classes.cell} align="left">
          <Button
            variant="outlined"
            sx={{
              display: "flex",
              flexFlow: "row nowrap",
              minWidth: "fit-content",
              alignSelf: "auto"
            }}
            onClick={async (e) => {
              e.stopPropagation();
              const taskId = task.id;
              await httpCallables.adminLMSFunctions({
                func_name: "removeLMSTaskData",
                task_id: taskId
              });
            }}>
            Remove task from LMS
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
}

function CourseTasks({ courseId }) {
  const classes = useStyles();
  const intl = useIntl();

  const [tasks, setTasks] = useState([]);
  const [questionTypes, setQuestionTypes] = useState({});

  useEffect(() => {
    httpCallables
      .adminFunctions({
        func_name: "getAllCourseTasks",
        course: courseId
      })
      .then((response) => {
        const tasks = [...response.data];
        setTasks(tasks);
      });

    httpCallables
      .adminFunctions({
        func_name: "getCourseQuestionTypes",
        course: courseId
      })
      .then((response) => {
        setQuestionTypes(response.data);
      });
  }, [courseId]);

  return (
    <Box>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="course tasks table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={5}></TableCell>
              <TableCell align="center" colSpan={3}>
                Questions
              </TableCell>
              <TableCell align="center" colSpan={1}></TableCell>
            </TableRow>
            <TableRow className={classes.tableHead}>
              <TableCell className={clsx(classes.cell)}>ID</TableCell>
              <TableCell className={classes.cell}>Title</TableCell>
              <TableCell className={classes.cell}>Type</TableCell>
              <TableCell className={classes.cell}>Due date</TableCell>
              <TableCell className={classes.cell}>Submissions</TableCell>
              <TableCell className={classes.cell}>OE</TableCell>
              <TableCell className={classes.cell}>FT</TableCell>
              <TableCell className={classes.cell}>MC</TableCell>
              <TableCell className={classes.cell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task, index) => {
              return <Tasks key={index} task={task} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box>
        <Typography sx={{ marginBottom: "8px", marginTop: "16px" }}>
          Questions:
        </Typography>
        {Object.entries(questionTypes).map((type, i) => {
          return (
            <Typography key={i} sx={{ marginBottom: "4px" }}>
              {intl.formatMessage({
                id: QUESTION_INTL[type[0]]?.translationId || "UNKNOWN_QT",
                defaultMessage:
                  QUESTION_INTL[type[0]]?.defaultMessage || "OLD_QT"
              })}
              : {type[1]}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}

export default CourseTasks;
