import { useState, useEffect } from "react";

function useScrollbarWidth() {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    const scrollbarMeasure = () => {
      // Create a div with scrollbars
      const scrollDiv = document.createElement("div");
      scrollDiv.style.width = "100px";
      scrollDiv.style.height = "100px";
      scrollDiv.style.overflow = "scroll";
      scrollDiv.style.position = "absolute";
      scrollDiv.style.top = "-9999px";

      // Append it to body
      document.body.appendChild(scrollDiv);

      // Measure the difference between its outer and inner width
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

      // Remove the div
      document.body.removeChild(scrollDiv);

      return scrollbarWidth;
    };

    setScrollbarWidth(scrollbarMeasure());

    // Recalculate on window resize
    const handleResize = () => {
      setScrollbarWidth(scrollbarMeasure());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return scrollbarWidth;
}

export default useScrollbarWidth;
