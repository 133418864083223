import React, { useEffect, useState } from "react";

import { firebaseFunctions, httpCallables } from "../../firebase";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid } from "@mui/x-data-grid";
import { useImpersonate } from "../../hooks";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  InputLabel,
  Select,
  DialogActions,
  DialogContent,
  MenuItem,
  Typography,
  LinearProgress,
  IconButton,
  Tooltip,
  FormControl
} from "@mui/material";
import { Link } from "react-router-dom";
import RemoveUserMenu from "./RemoveUserMenu";
import { useDispatch, useSelector } from "react-redux";
import FaceIcon from "@mui/icons-material/Face";
import { selectAlertsDuration } from "../../redux/firestoreSelectors";
import { enqueueFlashMessage } from "../../redux/userSlice";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: "pointer",
    color: "blue",
    textTransform: "underline"
  },
  container: {
    width: "100w",
    height: "100vh"
  },
  dialog: {
    backgroundColot: "red"
  },
  tableContainer: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3)
  },
  tableHead: {
    fontWeight: "800"
  }
}));

function CourseUsers({ courseId }) {
  const classes = useStyles();
  const { impersonate } = useImpersonate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentUser = useSelector((state) => state.user.auth);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));

  const [users, setUsers] = useState([]);
  const [openUsers, setOpenUsers] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("Student");

  const [usersFetched, setUsersFetched] = React.useState(false);

  useEffect(() => {
    setUsersFetched(false);
    httpCallables
      .adminFunctions({
        func_name: "getAllCourseUsers",
        course: courseId
      })
      .then((response) => {
        setUsers(response.data);
        setUsersFetched(true);
      });
  }, [courseId]);

  const saveUser = () => {
    httpCallables
      .adminFunctions({
        func_name: "addCourseUser",
        email: email.trim(),
        course_id: Number(courseId),
        name: name,
        role: role
      })
      .then(({ data }) => {
        if (data.success) {
          const { user } = data;
          setUsers([
            ...users,
            {
              id: user.id,
              name: user.uid,
              email: user.email,
              role,
              course_user: user.uid
            }
          ]);
        }
      });
    handleClose();
  };

  const handleClose = () => {
    setOpenUsers(false);
    setEmail("");
    setName("");
    setRole("Student");
  };

  const renderCreateCourseDialog = () => {
    return (
      <Dialog
        open={openUsers}
        PaperProps={{
          style: {
            // backgroundColor: "white",
          }
        }}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
        <DialogContent className={classes.dialog}>
          <TextField
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoFocus
            margin="dense"
            aria-label="course-user-email-input"
            id="email"
            label="email"
            type="text"
            value={email}
            fullWidth
          />
          <TextField
            variant="standard"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
            aria-label="course-user-name-input"
            autoFocus
            margin="dense"
            id="name"
            label="name"
            type="text"
            fullWidth
          />
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Role
          </InputLabel>
          <Select
            variant="standard"
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={role}
            displayEmpty
            className={classes.selectEmpty}>
            {["Student", "Teacher", "Admin"].map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setRole(item);
                  }}
                  value={item}>
                  {" "}
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveUser} color="primary">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const updateCourseUser = (row, field, data) => {
    httpCallables
      .courseUserFunctions({
        func_name: "updateCourseUser",
        course_user_id: row.id,
        data: data
      })
      .then((response) => {
        if (response.data.success) {
          setUsers((users) => {
            return users.map((u) =>
              u.id === response.data.user.id
                ? { ...u, [field]: response.data.user[field] }
                : u
            );
          });
          dispatch(
            enqueueFlashMessage({
              message: "User updated",
              duration: alertsDuration
            })
          );
        }
      });
  };

  const columns = [
    { field: "email", headerName: "Email", minWidth: 200 },
    { field: "name", headerName: "Name", minWidth: 300 },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <FormControl sx={{ minWidth: 130 }} size="small">
            <Select
              labelId="select-role"
              value={row.role}
              onChange={(event) =>
                updateCourseUser(row, "role", {
                  course_role: event.target.value
                })
              }>
              <MenuItem value={"Teacher"}>Teacher</MenuItem>
              <MenuItem value={"Student"}>Student</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
            </Select>
          </FormControl>
        );
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: ({ row }) => {
        return (
          <FormControl sx={{ minWidth: 130 }} size="small">
            <Select
              labelId="select-status"
              value={row.status}
              onChange={(event) =>
                updateCourseUser(row, "status", { status: event.target.value })
              }>
              <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
              <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
            </Select>
          </FormControl>
        );
      }
    },
    {
      field: "claim",
      headerName: "Claim",
      minWidth: 100,
      renderCell: ({ row }) => {
        return intl.formatMessage({
          id: `roles.${row.claim}`,
          defaultMessage: "User claim"
        });
      }
    },
    {
      field: "userConversations",
      headerName: "",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Link
              to={`/admin/conversations?course_id=${courseId}&user_id=${row.course_user}`}>
              User&apos;s conversations
            </Link>
          </Box>
        );
      }
    },
    {
      field: "userActions",
      headerName: "",
      width: 100,
      renderCell: ({ row }) => {
        return (
          currentUser.isAdmin && (
            <Box
              sx={{
                display: "flex",
                width: "100%"
              }}>
              <Tooltip title="Impersonate">
                <IconButton
                  onClick={() => {
                    impersonate(row.course_user);
                  }}
                  size="large">
                  <FaceIcon />
                </IconButton>
              </Tooltip>
              <RemoveUserMenu
                row={row}
                courseId={courseId}
                setUsers={setUsers}
              />
            </Box>
          )
        );
      }
    }
  ];

  const topUsers = [
    "gS7LsuEEP7OYp9ZjSEVOO0HLlIa2",
    "1bjgVIcI8dTpATOIZUEZPo3N8Dw1",
    "LaNTsYWwzyOuYHyeRESRVxvaPhL2"
  ];
  const usersSorted = [
    ...users.filter((u) => topUsers.includes(u.course_user)),
    ...users.filter((u) => !topUsers.includes(u.course_user))
  ];

  return usersFetched ? (
    <Box>
      {currentUser.isAdmin && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenUsers(true);
          }}
          sx={{ marginBottom: "16px" }}>
          Add User
        </Button>
      )}
      <div style={{ height: "max-content", width: "90%" }}>
        <DataGrid
          autoHeight={true}
          rows={users}
          columns={columns}
          pageSize={50}
        />
      </div>
      {renderCreateCourseDialog()}
    </Box>
  ) : (
    <Box sx={{ height: "32px", display: "flex", alignItems: "center" }}>
      <LinearProgress color="secondary" sx={{ width: "80%" }} />
    </Box>
  );
}

export default CourseUsers;
