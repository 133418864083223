import React from "react";
import clsx from "clsx";

// Redux Dependencies
import { useSelector } from "react-redux";
import {
  selectAvatar,
  selectIsSuggestions
} from "../../redux/firestoreSelectors";

import makeStyles from "@mui/styles/makeStyles";
import { Avatar } from "@mui/material";
import { selectIsImpersonation } from "../../redux/userSlice";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp } from "../../firebase";

// Styles
const useStyles = makeStyles((theme) => ({
  button: {
    color: "currentcolor"
  }
}));

export default function CustomAvatar({
  className,
  id = null,
  src = null,
  name = null,
  minimized = false,
  children = null
}) {
  const classes = useStyles();
  // selects avatar color
  // Redux State

  function getInitials(fullName) {
    try {
      const allNames = fullName.trim().split(" ");
      let initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, "");
      if (minimized) {
        initials = initials.slice(0, 1);
      }
      return initials;
    } catch (error) {
      return "A";
    }
  }
  function generateColor(str) {
    let color = "";
    if (!str) return color;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ("00" + value.toString(16)).substr(-2);
    }
    if (name === "insights") color = "ECC5FF";
    return color;
  }

  function getHexColorBrightness(hexColor) {
    const red = parseInt(hexColor.substr(1, 2), 16);
    const green = parseInt(hexColor.substr(3, 2), 16);
    const blue = parseInt(hexColor.substr(5, 2), 16);
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    if (brightness > 128) {
      return "#000000"; // Black for bright colors
    } else {
      return "#FFFFFF"; // White for dark colors
    }
  }

  return (
    <Avatar
      src={!children && src ? src : ""}
      className={clsx(classes.avatar, className, "sentry-mask")}
      alt={name}
      sx={{
        backgroundColor: `#${generateColor(id)}`,
        color: getHexColorBrightness(generateColor(id)),
        width: 32,
        height: 32
      }}>
      {children || getInitials(name)}
    </Avatar>
  );
}
